.list-padding li{
  padding-left: 8px;
}

.rounded-list {
    counter-reset: li; /* Initiate a counter */
    list-style: none; /* Remove default numbering */

    font: 15px 'trebuchet MS', 'lucida sans';
    padding: 0;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
  }

.rounded-list a{
    position: relative;
    display: block;
    padding: .5em .4em .5em 2em;
    margin: .9em 0;
    background: #eee;
    color: #333;
    text-decoration: none;
    border-radius: 6px;
    transition: all .3s ease-out;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding-left: 70px;
  }

  .rounded-list a:hover{
    background: #ddd;
    padding-left: 80px;
  }

  /*.rounded-list a:hover:before{
    transform: rotate(360deg);
  }*/

  .rounded-list a:before{
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -1.3em;
    top: 50%;
    margin-top: -1.3em;
    background: #4A235A;
    color: #fff;
    height: 40px;
    width: 40px;
    line-height: 32px;
    border: .3em solid #fff;
    text-align: center;
    font-weight: bold;
    border-radius: 40px;
    transition: all .3s ease-out;
  }